<template>
  <div>
    <v-tooltip
      v-if="productCategory.status === 1"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductCategory"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onDeactiveWarehouse"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para inhabilitar la categoria de producto</span>
    </v-tooltip>

    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductCategory"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onActiveWarehouse"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para habilitar la categoria de producto</span>
    </v-tooltip>

    <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-product-category-dialog
      v-model="openedEditDialog"
      :product-category="productCategory"
    />
  </div>
</template>

<script>
  import EditProductCategoryDialog from './EditProductCategoryDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProductCategoryActions',
    components: {
      EditProductCategoryDialog,
    },
    props: {
      productCategory: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProductCategory',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProductCategory',
        'deleteProductCategory',
        'activeProductCategory',
      ]),
      async onDeactiveWarehouse () {
        await this.deleteProductCategory({ id: this.productCategory.id })
        await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async onActiveWarehouse () {
        await this.activeProductCategory({ id: this.productCategory.id })
        await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>

<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-file-tree-outline"
          title="Categorias de productos"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :headers="headers"
            :items="productCategory"
            :loading="fetchingProductCategory"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template v-slot:top>
              <product-category-table-top />
            </template>
            <template v-slot:[`item.actions`]="{ item: productCategory }">
              <product-category-actions
                :product-category="productCategory"
              />
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ProductCategoryTableTop from './ProductCategoryTableTop'
  import ProductCategoryActions from './ProductCategoryActions.vue'

  export default {
    name: 'ProductLineTable',
    components: {
      ProductCategoryTableTop,
      ProductCategoryActions,
    },
    data () {
      return {
        headers: [
          {
            text: 'Codigo',
            value: 'description',
          },
          {
            text: 'Acciones',
            value: 'actions',
            align: 'center',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'productCategory',
        'fetchingProductCategory',
      ]),
    },
  }
</script>
